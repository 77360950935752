.header {
  width: 100%;
  background-color: #fff;
  padding: 2rem;
}

.innerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1322px;
  margin: auto;
}

.mobileMenu {
  height: 30px;

  @media only screen and (min-width: 800px) {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .mobileMenuActive {
    display: flex !important;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: auto;
    background-color: white;
    padding: 2rem;
    z-index: 100;
  }
}

.list {
  display: none;
  flex-direction: column;
  gap: 1.5rem;

  @media only screen and (min-width: 800px) {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 800px) {
  .list.active {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.mobileSplitter {
  background-color: var(--color-orange);
  width: 2px;

  @media only screen and (max-width: 800px) {
    display: block;
    height: 2px;
    width: 60vw;
    background-color: var(--color-orange);
  }
}

.closeMobileMenu {
  display: none !important;

  @media only screen and (max-width: 800px) {
    display: flex !important;
    color: var(--color-black);
    font-weight: 600;
    cursor: pointer;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  fill: var(--color-black);
  color: var(--color-black);
  width: 100%;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
}

.link:hover {
  fill: var(--color-orange);
  color: var(--color-orange);
}

.icon {
  height: 35px;
}

.text {
  font-weight: 600;
  text-transform: uppercase;

  @media only screen and (min-width: 800px) {
    font-size: 0.8rem;
    display: none;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 60px;
  width: auto;

  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
}

.logo {
  height: 100%;
  width: auto;
}

.red:hover {
  fill: #e53939 !important;
  color: #e53939 !important;
}
