.dropdown {
  position: relative;
  display: inline-block;
  font-size: 0.625rem;
  font-family: Verdana, Arial, sans-serif;
  padding: 0.5em 2.5em 0.5em 2em;
  min-width: 15em;
  cursor: pointer;
  user-select: none;
  /* border: 0.2em solid var(--color-orange); */
  border-radius: 50px;
  box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.45);
  outline: none;
  box-sizing: border-box;
  background: #f0f0f0;
  background: var(--color-grey);
  z-index: 10;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px #ed6f1e;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 10;
    top: 0;
    right: 5px;
    box-sizing: border-box;
    height: 100%;
    width: 3em;
    padding-top: 0.6em;
    border-left: 0.2em solid var(--color-grey);
    border-radius: 50px;
    background-color: var(--color-grey);
  }

  &::after {
    content: ""; /* If not empty make sure to set a charset meta tag */
    position: absolute;
    z-index: 10;
    box-sizing: border-box;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: initial;
    background: white;
    top: 50%;
    right: 5px;
    transform: translate(-90%, -50%);
    transition: clip-path 330ms ease;
    clip-path: polygon(0 0, 100% 0, 50% 100%, 50% 100%);
  }
}

.list {
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  /* !important will affect the rest of your CSS despite being in a layer */
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  min-width: 100%;
  max-height: 20em;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.2em solid var(--color-grey);
  border-top-width: 0.1em;
  border-radius: 0 0 0.4em 0.4em;
  box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.4);
  background: #f0f0f0;
  transition: transform 330ms ease, opacity 330ms ease;
  transform: translateY(1px);
  z-index: 5;

  &.hidden {
    max-height: 0;
    visibility: hidden;
  }
}
.value {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
  font-weight: 500;
  font-size: 1rem;
}
.option {
  padding: 0.2em 0.3em;
  font-size: 0.75rem;
  opacity: 0;
  animation-delay: calc(40ms * var(--index)) !important;
  background-color: var(--color-grey);
}

.active {
  &::after {
    clip-path: polygon(50% 0, 50% 0, 100% 100%, 0 100%);
  }
  .option {
    animation: drop 220ms ease forwards;
  }
}

.highlight {
  background-color: var(--color-orange);
  color: white;
  font-weight: bold;
}

.select {
  visibility: hidden;
  max-height: 0;
  position: absolute;
  padding: 0;
  margin: 0;
}

@keyframes drop {
  from {
    transform: translateY(-5px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
