.container {
  display: flex;
  width: 80%;
  border-radius: 50px;
  /* border: 2px solid white; */
  height: 45px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "poppins";
  /* padding: 0 1rem; */
  background-color: white;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  font-family: "poppins";
  color: black;
  background-color: white;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: 50px 0 0 50px;
}

.container:focus-visible {
  border-color: var(--color-orange);
  outline: none;
}
