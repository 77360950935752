.tyreList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  color: var(--color-grey);
}

.tyreList > input {
  width: 100%;
  border: 2px solid var(--color-grey);
}

.tyreList > div > div {
  border-color: var(--color-grey);
}

.tyreList > div > div:hover {
  border-color: var(--color-orange);
}

.tyreList > div > div > span:last-child,
.tyreList > div > div > div:last-child {
  display: none;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.fields {
  display: flex;
  gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.warning {
  color: var(--color-red);
  font-weight: 500;
  width: 100%;
}
