.button {
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;
  font-family: "poppins";
  text-decoration: none;
  white-space: nowrap;
  box-shadow: 0 1px 5px #00000061;
  transition: 0.3s;
  cursor: pointer;
  max-width: 400px;
}

.orange {
  background-color: var(--color-orange);
  border: 3px solid var(--color-orange);
  color: white;
}

.orange:hover {
  background-color: var(--color-dark-orange);
}

.red {
  background-color: var(--color-red);
  border: 3px solid var(--color-red);
  color: white;
}

.red:hover {
  background-color: var(--color-dark-red);
}
