.input {
  width: 80%;
  border-radius: 50px;
  border: 2px solid white;
  height: 45px;
  font-size: 1rem;
  font-weight: 500;
  font-family: "poppins";
  padding: 0.5rem 1rem;
}

.input:focus-visible {
  border-color: var(--color-orange);
  outline: none;
}
