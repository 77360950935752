.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
}

.title {
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.item span:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  margin: 0;
}
