.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  max-width: 1322px;
  margin: auto;
  padding: 2rem 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  gap: 2rem;
  width: 100%;
}

h1 {
  margin: 0;
}

@media only screen and (max-width: 900px) {
  .header {
    flex-wrap: wrap;
    justify-content: center;
  }
}
