.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1322px;
    margin: auto;
    padding: 2rem 1rem;
}

.buttonContainer {
    margin-top: 2rem;
}