.title {
  margin: 0;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 1000px;

  @media only screen and (min-width: 800px) {
    margin-top: 1rem;
  }
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  border: 2px solid var(--color-grey);
  border-radius: 6px;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.3s;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    border-bottom: 2px solid white;
    border-radius: 0;
    padding-bottom: 1rem;
  }
}

div.item:first-child {
  @media only screen and (max-width: 800px) {
    border-top: 2px solid white;
    padding-top: 1rem;
  }
}

.item:hover {
  @media only screen and (min-width: 800px) {
    margin-top: -1px;
    border-color: white;
  }
}

.column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    align-items: flex-start;
    width: 100%;
  }
}

.column span:first-child {
  color: var(--color-orange);
}

.column span:last-child {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.mobileTitle {
  display: none;

  @media only screen and (max-width: 800px) {
    display: block;
  }
}
