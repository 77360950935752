.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1322px;
  margin: auto;
  padding: 2rem 2rem;
}

.container h1 {
  text-align: center;
  line-height: 2.5rem;
  margin: 0;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background-color: var(--color-grey);
  border: 2px solid var(--color-grey);
  border-radius: 6px;
  padding: 1.5rem;
  width: 100%;
  text-decoration: none;
  transition: 0.3s;

  @media only screen and (min-width: 800px) {
    max-width: 250px;
    aspect-ratio: 1;
  }
}

.item:hover {
  background-color: var(--color-dark-grey);
  border-color: white;
}

.item:hover > .icon {
  transform: scale(1.05);
}

.icon {
  fill: var(--color-orange);
  height: 50px;
  transition: 0.3s;

  @media only screen and (min-width: 800px) {
    height: 100px;
  }
}

.text {
  color: white;
  margin: 0;
}
