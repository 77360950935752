.field {
  position: relative;
  width: 100%;
}

.field label {
  position: absolute;
  padding: 0.3rem;
  margin: 0.7rem;
  top: -25px;
  left: 0;
  background-color: #fff;
  border-radius: 6px;
  color: var(--color-black);
  font-weight: 600;
  transition: 0.5;
  pointer-events: none;
  z-index: 1;

  @media only screen and (max-width: 800px) {
    position: unset;
    padding: 0;
    margin: 0 0 0.5rem;
    display: block;
  }
}

.field input[value=""] ~ * {
  top: 0;
  color: #a1a1a1;
  font-weight: 500;
}

.field input:focus-visible ~ * {
  top: -25px;
  color: var(--color-black);
  font-weight: 600;
}

.field input,
.field select {
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid #cdc8c8;
  outline: none;
  font-weight: 500;
  font-family: "poppins";
  text-transform: uppercase;
  color: var(--color-black);
  width: 100%;
  background-color: white;
  height: 60px;
}

.field input:focus-visible {
  border-color: var(--color-orange);
}

.field input.error {
  border-color: var(--color-red);
  animation: error-field-bounce 0.5s ease-in-out;
}

.field input.error ~ span {
  color: var(--color-dark-red);
  margin: 0.5rem 0.5rem 0;
  display: block;
  font-weight: 500;
}

@keyframes error-field-bounce {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
