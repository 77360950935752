.outerContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  max-width: 1322px;
  margin: auto;
  padding: 2rem 1rem;
}

@media only screen and (max-width: 800px) {
  .outerContainer {
    flex-direction: column;
  }
}

.menuContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-grey);
  border-radius: 10px;
  padding: 2rem;
  width: 30%;
}

@media only screen and (max-width: 800px) {
  .menuContainer {
    width: 100%;
  }
}

.menuItem {
  font-weight: 500;
  color: white;
  width: 100%;
  cursor: pointer;
}

.displayContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-grey);
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
}
