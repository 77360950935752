.container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid var(--color-light-grey);
}

.title {
  color: var(--color-black);
  font-size: 1rem;
}

.header svg {
  fill: var(--color-orange);
  cursor: pointer;
  height: 30px;
  aspect-ratio: 1;
}

.header svg:hover {
  fill: var(--color-dark-orange);
}

.items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  color: var(--color-grey);
  font-weight: 500;
  overflow-x: auto;
  overflow-y: hidden;
}

.itemsHeader {
  color: var(--color-orange);
  font-weight: 600;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-light-grey);
  white-space: nowrap;
}

.size {
  flex-basis: 50%;
}

.quantity {
  flex-basis: 10%;
}

.price {
  flex-basis: 15%;
}

.cost {
  flex-basis: 15%;
}

.delete {
  flex-basis: 10%;
  text-align: center;
}

.removeIcon {
  height: 20px;
  fill: var(--color-red);
  cursor: pointer;

  :hover {
    fill: var(--color-dark-red);
  }
}
