.outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100vh;
  width: 100%;
  background-color: #000000ad;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.container {
  padding: 2rem;
  background-color: white;
  border-radius: 6px;
  overflow-y: auto;
  max-width: 600px;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.ref {
  color: var(--color-orange);
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.doubleField {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.totals {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  white-space: nowrap;
  width: 100%;
}

.price {
  font-weight: 700;
  color: var(--color-orange);
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  line-height: 1.8rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
