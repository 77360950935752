* {
  box-sizing: border-box;
}

:root {
  font-family: "Poppins", Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-black: #3c3c3c;
  --color-light-grey: #dadada;
  --color-grey: #3a3a3a;
  --color-orange: #de8801;
  --color-dark-orange: #b97407;
  --color-red: #d50606;
  --color-dark-red: #ad0404;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
