.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 1000px;
  margin-top: 1rem;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-orange);
  letter-spacing: 1px;

  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.tableHeader > span {
  width: 100%;
}

.tableHeader > span:last-child {
  width: 10%;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  border: 2px solid var(--color-grey);
  border-radius: 6px;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.3s;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.item:hover {
  margin-top: -1px;
  border-color: white;
}

.column {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    justify-content: space-between;
  }
}

.column:last-child {
  width: 10%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
}

.mobileTitle {
  display: none;

  @media only screen and (max-width: 800px) {
    display: block;
  }
}

.delete {
  height: 20px;
  fill: white;
}

.delete:hover {
  fill: var(--color-dark-red);
}
