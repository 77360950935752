.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #000000ad;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem;
  background-color: white;
  border-radius: 6px;
  width: 100%;
  max-width: 400px;
  overflow-y: auto;
  max-height: 90%;

  @media only screen and (max-width: 800px) {
    padding-top: 7rem;
  }
}

.field {
  position: relative;
}

.field label {
  position: absolute;
  padding: 0.3rem;
  margin: 0.7rem;
  top: -25px;
  left: 0;
  background-color: #fff;
  border-radius: 6px;
  color: var(--color-black);
  font-weight: 600;
  transition: 0.5;
  pointer-events: none;
}

.field input[value=""] ~ * {
  top: 0;
  color: #a1a1a1;
  font-weight: 500;
}

.field input:focus-visible ~ * {
  top: -25px;
  color: var(--color-black);
  font-weight: 600;
}

.field input,
.field select {
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid #cdc8c8;
  outline: none;
  font-weight: 500;
  font-family: "poppins";
  text-transform: uppercase;
  color: var(--color-black);
}

.field input:focus-visible {
  border-color: var(--color-orange);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
